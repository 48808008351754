<template>
  <div class="main">
    <!-- 头部 -->
    <top></top>
    <!-- 导航 -->
     <navigation></navigation>
    <div class="breadcrumb">
      <div class="icon"><i class="el-icon-s-home"></i></div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>职工之家</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="left">
        <div class="title">文化建设</div>
        <div class="title-2 checked">· 活动介绍</div>
        <div class="title-2">· 奖励公示</div>
      </div>
      <div class="right">
        <div class="title">
          <img src="../assets/img/circle.png" alt="" />
          <div class="text">政务动态</div>
        </div>
        <ul class="list" @click="toDetail">
          <li class="item">
            <div class="headline">· 致全体干部职工的一封信</div>
            <div class="date">2022-12-23</div>
          </li>
          <li class="item">
            <div class="headline">
              · 在市地方铁路公司“两优一先”表彰大会上的讲话
            </div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">
              · 坚持改革创新 推动高质量发展 以优异成绩迎接党的以优异成绩迎接党的
            </div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">· 分享红色经典 汲取奋发力量</div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">
              · 中国共产党第十九届中央委员会第六次全体会议公报
            </div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">
              · 中共中央关于党的百年奋斗重大成就和历史经验的决议
            </div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">
              · 坚持改革创新 推动高质量发展 以优异成绩迎接党的...
            </div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">· 分享红色经典 汲取奋发力量</div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">
              · 中国共产党第十九届中央委员会第六次全体会议公报
            </div>
            <div class="date">2022-12-16</div>
          </li>
          <li class="item">
            <div class="headline">
              · 中共中央关于党的百年奋斗重大成就和历史经验的决议
            </div>
            <div class="date">2022-12-16</div>
          </li>
        </ul>
        <div class="block">
          <el-pagination layout="prev, pager, next" :total="50">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from '../components/Bottom.vue';
import Navigation from "../components/Navigation.vue";
import Top from "../components/Top.vue"
export default {
  components:{
    Navigation,
    Top,
    Bottom
  },
  data() {
    return {
      input: "",
     
    };
  },
  methods:{
    toDetail(){
      this.$router.push("/detail/")
    }
  }
 
};
</script>

<style lang="scss" scoped>
.main {
  min-width: 1139px;
  width: 100%;
}

.breadcrumb {
  display: flex;
  height: 120px;
  background-color: rgba(233, 234, 236, 1) !important;
  background: url(../assets/img/breadcrumb.png);
  background-repeat: no-repeat;
  background-size: 43.6% 93.2%;
  background-position: 90% 100%;
  padding: 48px 110px;
  line-height: 24px;
  margin-bottom: 0;
  .icon {
    font-size: 24px;
    color: #1f4a99;
  }
  .el-breadcrumb {
    line-height: 24px;
    margin-left: 4px;
	font-family: 'Regular';
  }
  /deep/.el-breadcrumb__inner.is-link {
    color: #1f4a99 !important;
  }
}

.content {
  max-width: 976px;
  margin: 40px auto;
  min-height: 500px;
  display: flex;

  .left {
    max-width: 300px;
    min-width: 235px;
    margin-right: 40px;
    background-color: rgba(242, 242, 242, 1);
    .title {
      height: 52px;
      line-height: 52px;
	  font-family: 'SemiBold';
      background: linear-gradient(
        89.94deg,
        rgba(31, 74, 153, 1) -1.05%,
        rgba(27, 126, 242, 1) 99.55%
      );
      color: #ffffff;
      padding-left: 16px;
      border-radius: 8px 8px 0px 0px;
      font-size: 20px;
      font-weight: 500;
    }
    .title-2 {
      line-height: 52px;
      padding-left: 20px;
      font-size: 16px;
    }
    .checked {
      color: rgba(31, 74, 153, 1);
	  font-family: 'Regular';
    }
  }
  .right {
    flex: 1;
    .title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(187, 187, 187, 1);
      padding-bottom: 12px;
      img {
        width: 8px;
        height: 8px;
        margin-right: 11px;
      }
      .text {
        color: rgba(51, 51, 51, 1);
        font-size: 20px;
      }
    }
    .list {
		font-family: 'Regular';
      cursor: pointer;
      margin-top: 20px;
      margin-bottom: 30px;
      .item {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid rgba(232, 232, 232, 1);
        .headline {
          width: 407px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .block {
      display: flex;
      justify-content: center;
      /deep/.el-pager li.active {
        border: 1px solid rgba(232, 232, 232, 1) !important;
        color: #777777;
      }
      /deep/.el-pager li {
        color: #777777;
      }
    }
  }
}


</style>
